<template>
    <div class="mct-grid">
        <div class="mct-grid-scroll">         
            <table class="table table-striped table-bordered table-sm table-hover">
                <thead>
                    <tr>
                        <th :style="showModal ? 'width: 35px!important;' : 'width: 75px!important;'">Ações</th>
                        <th v-for="(column, index) in columns" @click="toggleOrder($event,column.nome)" :key="index" v-if="column.visivel_lista !== false && column.visivel_lista !== 'false'"
                            :style="column.tamanhoCol > 0 ? `width: ${column.tamanhoCol}px!important;` : ''">
                            <span>{{column.titulo}}</span>
                            <span class="dv-table-column" v-if="column.nome === query.column">
                                <span v-if="query.direction === 'desc'">&darr;</span>
                                <span v-else>&uarr;</span>
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                <tr v-for="(row,index) in data.data" :key="index"  @click.prevent.stop="setDescricao($event,row)">
                    <td class="is-icon">
                        <i class="fa fa-pencil text-white mct-fa bg-primary" aria-hidden="true" v-show="!showModal" @click="redirect($event,`${$route.fullPath}/${getIndexes(row)}/editar`)"></i>
                        <!--<i class="fa fa-times text-white bg-danger mct-fa" aria-hidden="true" v-show="!showModal" @click="redirect($event,`${$route.fullPath}/${getIndexes(row)}/deletar`)"></i>-->
                        <i class="fa fa-search text-white bg-secondary mct-fa" aria-hidden="true" v-show="!showModal" @click="redirect($event,`${$route.fullPath}/${getIndexes(row)}/visualizar`)"></i>
                        <i class="fa fa-hand-pointer-o text-white bg-info mct-fa" aria-hidden="true" v-show="showModal" @click.prevent.stop="setDescricao($event,row)"></i>
                        
                        <!--<a @click="redirect($event,`${$route.fullPath}/${getIndexes(row)}/editar`)" class="btn btn-primary btn-sm" aria-label="Editar" v-show="!showModal"><i class="fa fa-pencil" aria-hidden="true"></i></a>
                        <a @click="redirect($event,`${$route.fullPath}/${getIndexes(row)}/deletar`)" class="btn btn-danger btn-sm" aria-label="Deletar" v-show="!showModal"><i class="fa fa-times" aria-hidden="true"></i></a>
                        <a @click="redirect($event,`${$route.fullPath}/${getIndexes(row)}/visualizar`)" class="btn btn-secondary btn-sm" aria-label="Visualizar" v-show="!showModal"><i class="fa fa-search" aria-hidden="true"></i></a>
                        <button type="button" class="btn btn-info btn-sm" aria-label="Visualizar" v-show="showModal" @click.prevent.stop="setDescricao($event,row)"><i class="fa fa-hand-pointer-o" aria-hidden="true"></i></button>-->
                    </td>
                    <!--<td v-for="(value, key) in row" :key="key" v-if="getVisivel(key) !== false">{{ value }}</td>-->
                    <td v-for="(column, key) in columns" :key="key" v-if="column.visivel_lista !== false && column.visivel_lista !== 'false'">{{ column.subtabela && row[column.subtabela] != null ? row[column.subtabela][column.subtabelacampo] : 
                                                                            /*column.origem && row[column.origem] != null ? row[column.origem][column.origem_campo] :*/ 
                                                                            column.tipo == 'checkbox' && row[column.nome] == '1' ? 'S' :
                                                                            column.tipo == 'checkbox' && row[column.nome] == '0' ? 'N' :
                                                                            row[column.nome] }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>   
</template>
<script>
export default {
    name: 'MctGridListaSB',
    props: ['id', 'columns', 'data', 'query', 'source', 'showModal'],
    methods: {                
        toggleOrder(ev, column) {
            ev.preventDefault()
            this.$emit('toggleOrder', column)    
        },
        setDescricao (ev, data){
            ev.preventDefault()
            if (this.showModal){
                this.$emit('setDescricao', data)
            }
        },
        getVisivel (key) {
            for (var index in this.columns) {
                if (this.columns[index]['nome'] == key){
                    if (typeof this.columns[index]['visivel_lista'] == 'undefined'){
                        //console.log('false') 
                        return true                         
                    }else{
                        //console.log('true')
                        return this.columns[index]['visivel_lista']  
                    }        
                }
            }
            return false // se nao localizar a coluna no columns
            //find(columns, key, 'nome')[0]['visivel_lista']
        },
        redirect (ev, link) {
            ev.preventDefault();
            
            this.$router.push(link)
        },
        getIndexes (row) {
            var indexes = ''
            if (typeof this.id === 'object'){
               for (var index in this.id) {               
                    if (indexes === ''){
                        indexes = row[this.id[0]]
                    } else {    
                        indexes = indexes + '/' + row[this.id[index]]
                    }
               }
            } else {
                indexes = row[this.id]   
            }
            return indexes
        }               
    } 
}
</script>
